<!-- 스폰서 컨텐츠 리스트 -->
<template>
  <v-container
          id="Article"
          fluid
          tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
        <base-material-card
                color="green">
          <template v-slot:heading>
            <h1 class="display-2 font-weight-regular">
             스폰서 컨텐츠
            </h1>
          </template>
          <v-card-text>
            <!--상단 부분-->
            <v-row justify="space-between" align="center">
              <!--매체 선택 박스-->
              <v-col cols="2">
                <template v-if="resultName">
                  <v-select
                          :items="config.mediaOptions"
                          v-model="vModel.media"
                          label="매체를 선택해주세요."
                          color="secondary"
                          @change="mediaChange()" />
                </template>
              </v-col>
              <!--신규 등록 버튼-->
              <v-btn :disabled="!vModel.media"
                     color="warning"
                     @click="createAds">
                신규 등록
              </v-btn>
            </v-row>
            <!--본문 테이블 리스트 :: 리스트 있을때만 나옴-->
            <v-row justify="space-between" align="center" v-if="resultList.meta">
              <v-col cols="12">
                <v-data-table
                        :headers="config.headers"
                        :items="resultList.data"
                        :items-per-page="Number(resultList.meta.perPage)"
                        item-key="key"
                        no-data-text="컨텐츠가 없습니다."
                        hide-default-footer>
                  <template v-slot:item="{ item, index }">
                    <tr @click="tableRowClick(item)">
                      <td class="text-center">{{resultList.meta.total - index}}</td>
                      <td class="text-center">{{item.block_no}}</td>
                      <td class="text-center">
                        <img :src="item.thumbnail_url" style="max-width:100px;max-height:100px;" />
                      </td>
                      <td class="text-center"><span @click="tableRowClick(item)">{{item.title}}</span></td>
                      <td class="text-center">{{item.writer}}</td>
                      <td class="text-center">{{item.start_date}} - {{item.end_date}}</td>
                      <td class="text-center">
                        <v-btn small @click="listDelete(item.key)"><v-icon>mdi-delete</v-icon></v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-pagination
                        v-model="vModel.page"
                        :length="resultList.meta.lastPage"
                        :total-visible="10"
                        @input="paginationClick"
                        circle
                        class="justify-center mt-5"
                        next-icon="mdi-menu-right"
                        prev-icon="mdi-menu-left"/>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
            v-model="vModel.snackbar"
            :type="vModel.snackbarType"
            v-bind="{
      'top': true,
      'right': true
    }">
        <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        media: null,
        list: { data: [] },
        listDelete: null,
      },
      vModel: {
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
        valid: true,
        media: '',
        page: 1
      },
      config: {
        mediaOptions: [],
        dragOptions: {
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost'
        },
        headers: [
          { text: 'No', value: 'no', align: 'center' },
          { text: 'Block No', value: 'block_no', align: 'center' },
          { text: '썸네일', value: 'thumbnail_url', align: 'center' },
          { text: '제목', value: 'title', align: 'center' },
          { text: '작성자', value: 'writer', align: 'center' },
          { text: '기간', value: 'date', align: 'center' },
          { text: '삭제', value: 'hidden', align: 'center' },
        ],
      }
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 스폰서 컨텐츠 등록 */
    createAds () {
      if (this.$route.name === 'Article') {
        /* cp */
        this.$router.push({ 'name': 'ArticleRegister' })
      } else {
        /* admin */
        this.$router.push({ 'name': 'contentsArticleRegister', params: { media_id: this.vModel.media } })
      }
    },
    /* 매체값 변경시 */
    mediaChange () {
      this.response.list = { data: [] }
      if (this.vModel.media) {
        this.$router.replace({ 'name': 'contentsArticle', query: { media: this.vModel.media } })
        this.adsList()
      }
    },
    /* 스폰서 컨텐츠 리스트 클릭시 */
    tableRowClick (item) {
      if (this.$route.name === 'Article') {
        /* cp */
        this.$router.push({ 'name': 'ArticleView', params: { article_id: item.key } })
      } else {
        /* admin */
        this.$router.push({ 'name': 'contentsArticleView', params: { media_id: this.vModel.media, article_id: item.key } })
      }
    },
    /* 스폰서 컨텐츠 리스트 페이지네이션 클릭시 */
    paginationClick (page) {
      this.vModel.page = page
      let send = {
        page: page
      }
      if (this.$route.name === 'Article') {
        /* cp */
        this.$router.replace({ name: 'Article', query: send })
      } else {
        /* admin */
        this.$router.replace({ name: 'contentsArticle', query: send })
      }
      this.adsList()
    },
    /* 페이지 로드시 */
    refresh () {
      if (this.$route.name === 'Article') {
        /* cp */
        if (this.$route.query.page) {
          let send = {
            page: this.$route.query.page
          }
          this.$router.push({ name: 'Article', query: send })
        }
        this.mediaList()
      } else {
        /* admin */
        this.mediaList()
        /* 선택했던 매체값이 있을 때 */
        if (this.$route.query.media) {
          if (this.$route.query.page) {
            let send = {
              page: this.$route.query.page
            }
            this.$router.push({ name: 'contentsArticle', query: send })
          }
          this.adsList()
        }
      }
    },
    /* 매체 리스트 가져오기 */
    mediaList () {
      let send = {
        per: 999,
        page: 1,
        orderby: 'kor_name',
        direction: 'asc',
        contract_status_id: 3,
      }
      this.xhttp({
        url: '/media',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.media = response.data.data.media
          this.config.mediaOptions = this.response.media.map(function ($value) {
            return { value: $value.key, text: $value.kor_name }
          })
          /* cp */
          if (this.$route.name === 'Article') {
            this.adsList()
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 스폰서 컨텐츠 리스트 가져오기 */
    adsList () {
      if (this.$route.name === 'Article') {
        /* cp */
        this.vModel.media = this.response.media[0].key
      } else {
        /* admin */
        if (this.$route.query.media) {
          this.vModel.media = this.$route.query.media
        }
      }
      let send = {
        page: this.vModel.page
      }
      this.xhttp({
        url: '/' + this.vModel.media + '/article',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.list = response.data.data
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 스폰서 컨텐츠 삭제 */
    listDelete (val) {
      if (confirm('삭제 하시겠습니까?')) {
        this.xhttp({
          url: '/article/' + val,
          method: 'delete',
          data: null
        }).then((response) => {
          if (response.status === 200) {
            this.response.listDelete = response.data.data
            this.snack('success', '삭제 완료되었습니다.')
            this.adsList()
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
  },
  computed: {
    resultList () {
      if (this.response.list) {
        return this.response.list
      } else {
        return false
      }
    },
    resultName () {
      if (this.$route.name === 'Article') {
        return false
      } else {
        return true
      }
    }
  },
}
</script>

<style>
  textarea { resize: none; min-height: 100px;}
  #Article .flip-list-move {
    transition: transform 0.5s;
  }
  #Article .no-move {
    transition: transform 0s;
  }
  #Article .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  #Article table span {
    cursor: pointer;
  }
  #Article table span:hover {
    text-decoration: underline;
  }
</style>
